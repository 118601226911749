<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :loading="loadingCases"
      loading-text="Loading... Please wait"
      :items="casesLocal"
      disable-pagination
      :hide-default-footer="true"
    >
      <template v-slot:item.thumb="{ item }">
        <v-icon class="py-3" large> mdi-{{ item.thumb }}</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-2 primary--text" small outlined elevation="0" @click="goToAccountPage(item)">
          view <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <template v-if="loadingCases && casesLocal.length == 0">
      <v-skeleton-loader
        v-for="(page, i) in 10"
        :key="'skeletonloader-' + i"
        type="list-item"
        ref="skeleton"
        class="mx-auto my-0"
      ></v-skeleton-loader>
    </template>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    //case name - name -address - traveldate - enddate - phone number
    return {
      loadedCasesAlready: false,
      loadingCases: false,
      headers: [
        { text: 'Type', align: 'start', sortable: false, value: 'thumb' },
        { text: 'Case Name', value: 'case_name' },
        { text: 'Name', value: 'name' },
        { text: 'Address', value: 'address' },
        { text: 'End Date', value: 'end_date' },
        { text: 'Phone Number', value: 'phone_number' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    };
  },
  computed: {
    casesLocal() {
      return this.$store.state.cases.map((caseItem, index) => {
        return {
          index: index,
          thumb: 'folder',
          case_name: caseItem.name,
          name: caseItem.firstname + ' ' + caseItem.lastname,
          address: caseItem.address,
          end_date: moment(caseItem.quarantineEndDate, 'YYYY-MM-DD').format('DD-MMM-YYYY'),
          phone_number: caseItem.phoneNumber,
          actions: null,
          keycloakId: caseItem.keycloakId
        };
      });
    },
    userObject() {
      return this.$store.state.userObject;
    }
  },
  watch: {
    userObject(n, o) {
      this.loadingCases = true;
      if (n && n != o) {
        this.$store.dispatch('getCases').then(() => {
          this.loadingCases = false;
          this.loadedCasesAlready = true;
        });
      }
    }
  },
  created() {
    this.loadingCases = true;
    this.$store.dispatch('getCases').then(() => {
      this.loadingCases = false;
    });
  },
  methods: {
    goToAccountPage(item) {
      //reset data
      this.$store.commit('SET_ACCOUNT_DATA', {});
      this.$store.commit('SET_CASES_DATA', []);

      this.$router
        .push({ name: 'AccountPage', params: { accountId: item.keycloakId, caseId: this.$store.state.cases[item.index].id } })
        .catch(() => 'router catch');
    }
  }
};
</script>

<style>
</style>