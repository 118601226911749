<template>
  <v-container fluid v-if="hasAccessToPage">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="10">
        <CasesTable />
      </v-col>
    </v-row>
  </v-container>
  <NoAccessMessage v-else />
</template>

<script>
import CasesTable from '@/views/CasesPage/components/cases-table.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import NoAccessMessage from '@/components/generic/no-access-message.vue';

export default {
  mixins: [SharedFunctions],
  components: {
    CasesTable,
    NoAccessMessage
  },
  data() {
    return {
      hasAccessToPage: false
    };
  },
  created() {
    this.hasAccessToPage = this.hasAccessToPageMixin();
  }
};
</script>